<template>
  <div
    class="prepaid-recharge"
    :class="{
      'pc-scale': !$store.state.mobile,
    }"
  >
    <img
      class="recharge-tips"
      src="@/assets/task/45d62f80fd34ff2ccc349f0fb0e28ae.png"
      alt=""
      srcset=""
    />
    <div class="tips">我的补给箱</div>
    <!-- 价格100，500，1000，2000，5000，10000就行 -->
    <div class="recharge">
      <div class="recharge-item">
        <div>充值金额</div>
        <div>首次补给</div>
      </div>
      <div class="recharge-item" v-for="(item, index) in list" :key="index">
        <div style="font-weight: bold">{{ item }}</div>
        <div @click="getChongzhils(index + 1)">
          <img src="@/assets/task/recharge.png" alt="" srcset="" />
        </div>
      </div>
    </div>
    <div class="win-box" v-if="money">
      <div class="win">
        <div
          class="win-conm"
          style="
            color: white;
            display: flex;
            align-items: center;
            flex-flow: row;
            justify-content: center;
          "
        >
          <div class="win-conm">
            <div class="win-tips">恭喜获得</div>
          </div>
          <div>{{ money }}</div>
          <img src="@/assets/img/money.png" style="width: 24px" />
        </div>
        <div class="win-span2" @click="money = null">放入背包</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [100, 500, 1000, 2000, 5000, 10000],
      money: null,
    };
  },
  methods: {
    async getChongzhils(id) {
      const params = {
        player_id: localStorage.getItem("id"),
        id,
      };
      const { data } = await this.$axios.post(
        "/index/Activity/czls",
        this.$qs.stringify(params)
      );

      if (data.status) {
        this.money = data.data;
        this.$message.success("领取成功");
      } else {
        this.$message.error(data.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.win {
  width: 450px;
  padding: 20px;
  background-color: #1c2230;
  position: relative;
  box-sizing: border-box;
  border-radius: 10px;

  .win-span2 {
    padding: 10px 0;
    height: 20px;
    background-color: #3088ff;
    background-size: 100% 100%;
    border: 1px solid #3088ff;
    color: #000000;
    display: flex;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-top: 15px;
  }

  .win-span2:hover {
    cursor: pointer;
  }
}

//中奖后盒子
.win-box {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
  display: grid;
  place-items: center;
}

.pc-scale {
  transform: scale(1.3) !important;
  margin-top: 200px !important;
  padding-bottom: 180px !important;
  // padding-bottom: 150px !important;
}
.prepaid-recharge {
  color: #fff;
  text-align: center;
  // padding-bottom: 50px;
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
  transform: scale(0.9);

  .tips {
    font-size: 26px;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .recharge-tips {
    width: 95%;
    max-width: 500px;
  }
  .recharge {
    max-width: 600px;
    margin: 0 auto;
    margin-top: 30px;
    &-item:first-child {
      margin-bottom: 20px;
    }
    &-item {
      display: flex;
      align-items: center;
      width: 100%;
      & > div {
        flex: 1;
        cursor: pointer;
        font-size: 18px;
        img {
          width: 60%;
          max-width: 100px;
        }
      }
    }
  }
}
</style>
