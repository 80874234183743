<template>
  <div class="lrf-tabs">
    <div class="tabs">
      <div
        class="tabs_item"
        @click="activeName = item.componentName"
        :class="{ active_item: activeName == item.componentName }"
        v-for="item in list"
        :key="item.componentName"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: String,
    },
    list: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  created() {
    if (!this.active && this.list.length) {
      this.active = this.list[0].componentName;
    }
  },
  computed: {
    activeName: {
      get() {
        return this.active;
      },
      set(name) {
        this.$emit("update:active", name);
      },
    },
  },
};
</script>

<style lang="less">
@media screen and (max-width: 768px) {
  .tabs {
    padding: 3px 10px !important;
    margin-bottom: 10px !important;
    height: 35px !important;
    &_item {
      font-size: 16px !important;
    }
  }
}
</style>
<style lang="less" scoped>
.lrf-tabs {
  color: #fff;
  .tabs {
    display: flex;
    padding: 5px 20px;
    height: 50px;
    display: flex;
    justify-content: center;
    // width: 50vw;
    margin-bottom: 20px;
    margin-top: 10px;
    align-items: center;
    // overflow-x: hidden;
    // background-color: #3088FF;
    &_item {
      flex: 1;
      text-align: center;
      display: grid;
      place-items: center;
      min-width: 80px;
      max-width: 150px;
      font-size: 25px;
      color: #fff;
      border: 1px solid #3088ff;
      cursor: pointer;
      height: 100%;
      margin-right: 10px;
      user-select: none;
    }

    .active_item {
      color: #fff;
      background-color: #3088ff;
      font-weight: bold;
    }
  }
}
</style>
