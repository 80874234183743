<template>
  <div class="task-center" >
    <myinform></myinform>
    <!-- <myslide></myslide> -->
    <!-- my task center -->
    <LrfTabs :active.sync="activeName" :list="getTabs" />

    <keep-alive>
      <transition>
        <Component :is="activeName"></Component>
      </transition>
    </keep-alive>
  </div>
</template>

<script>
import myslide from "@/components/my_slide1.vue";
import myinform from "@/components/my_inform.vue";
import {
  SignDay,
  BindBoxTask,
  CombatTask,
  PrepaidRecharge,
  PasswordPacket,
  LrfTabs,
} from "./components";
export default {
  components: {
    myslide,
    myinform,
    SignDay,
    BindBoxTask,
    CombatTask,
    PrepaidRecharge,
    PasswordPacket,
    LrfTabs,
  },
  props: ['yidongmenu', 'showNav', 'openHongbao'],
  data() {
    return {
     
      activeName: "",
      tabs: [
        {
          componentName: "SignDay",
          key: "sign",
          name: "每日签到",
        },
        {
          key: "openbox",
          componentName: "BindBoxTask",
          name: "宝箱任务",
        },
        {
          key: "battle",
          componentName: "CombatTask",
          name: "对战任务",
        },
        {
          key: "recharge",
          componentName: "PrepaidRecharge",
          name: "充值补给",
        },
        // {
        // 	componentName: "PasswordPacket",
        // 	name: "口令红包",
        // },
      ],
      info: {
        sign: 0,
        openbox: 0,
        battle: 0,
        recharge: 0,
      },
    };
  },
  methods: {
  
  },
  computed: {
    getTabs() {
      const result = this.tabs.filter((item) => this.info[item.key]);
      if (result.length > 0) this.activeName = result[0].componentName;
      return result;
    },
  
  },
  created() {
    let _this = this;
    _this.$axios
      .post("/admin/Activity/openOfflist", _this.$qs.stringify({}))
      .then((res) => {
        if (res.data) {
          this.info = res.data.data[0];
        }
      });
  },
  mounted() {
    if (this.$route.query.type == "盲盒福利") {
      this.activeName = "BindBoxTask";
    }
    if (this.$route.query.type == "对战福利") {
      this.activeName = "CombatTask";
    }
    if (this.$route.query.type == "充值福利") {
      this.activeName = "PrepaidRecharge";
    }
  },
};
</script>

<style lang="less" scoped src="./task-center.less"></style>
