<template>
  <div class="sign-day">
    <img
      class="sign_tips"
      src="@/assets/task/4c0298b7033572834c21c8c4ba92b3e.jpg"
      alt=""
      srcset=""
    />
    <span class="total_sign-num"
      >本月累计签到 <span style="font-size: 48px">{{ dayNum }}</span> 天</span
    >
    <span class="sign_immediates" @click="startSign" style="cursor: pointer">
      <img
        v-if="!status"
        class="sign_immediate"
        src="@/assets/task/sign_btn.png"
        alt=""
        srcset=""
      />
      <img
        v-else
        class="sign_immediate"
        src="@/assets/task/signin_btn.png"
        alt=""
        srcset=""
      />
    </span>
    <div class="accumlate">
      <div
        class="accumlate-item"
        v-for="item in accumlates"
        :key="item.day"
        @click="contniuSignRecharge(item.day)"
      >
        <img :src="item.img" alt="" srcset="" />
        <span
          >累计 <span style="font-size: 24px">{{ item.day }}</span> 天领取</span
        >
      </div>
    </div>

    <div
      class="sign-rule"
      :class="{
        'sign-rule-box': !$store.state.mobile,
      }"
    >
      <div class="content">
        <div class="content-item" v-for="item in accumlates" :key="item.day">
          <img :src="tipsImage" alt="" srcset="" />
          <span>当月累计签到 {{ item.day }} 天获得最高 {{ item.jb }}游戏币随机奖励</span>
        </div>
      </div>
    </div>
    <div class="win-box" v-if="money">
      <div class="win">
        <div
          class="win-conm"
          style="
            color: white;
            display: flex;
            align-items: center;
            flex-flow: row;
            justify-content: center;
          "
        >
          <div class="win-conm">
            <div class="win-tips">恭喜获得</div>
          </div>
          <div>{{ money }}</div>
          <img src="@/assets/img/money.png" style="width: 24px" />
        </div>
        <div class="win-span2" @click="money = null">放入背包</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tipsImage:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAbCAYAAABiFp9rAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAAsSAAALEgHS3X78AAADGUlEQVRIx5XWS6hWVRQH8N/+uoRWFJQQ4U64ZYNETSqJBlkkivkgsmMRghHUVLTHRLNUuvaGaNLMgZEN2mH5SFMiLBzlwK5mQg/DdkRhT0ihQafB2Rc+P77vu8c9O2vttf/r/z977bWCSVad4o14CAswC9cU1584gX3YFaqch50ThgDMxRiWYRyHcBw/ly3XYTYWY24B3BiqPN4KqE6xgw14DnuxNVT52CSs5+FZ3I+tGAtV/m8gUJ3ipXgbC/FYqPKeyaTtiV+KHfgUq0OV/53wdbo2BWzHHbgzVHlPneIlFwMUqvxRib8d28uZFwJhLR7AilDlb4ptpE7xtTrF0YsA+w6LcB/WXSBdneIMfI0nQpV39sixGB/gZbwSqny+pYyrioyzQpVPTzDajCO9ICXDg/i47Dlep7i8JbP3cLTECXWK0/ATFoUqfzYgu1GcxJRi2oP1RaZhrO7WlEXsYCXODAIp2Z3Gq12mFYXd5jrFqUPiDuN7rOxorvL+Fmq8hDNd31PxPE7UKa4YErcfCzua6v6ihebn8FQf1w3YXae4t05xZh//UczpIPZkOgws4eAA9zKM1ylu6ZHzR+UfXYlzbYDKemuIb6rm6Tpap3h1sf2Fyzv4G5e1QSiV/uQk23Zjeajy7+X7KvwzgowZLdmswl0DfN9iXajyvh779cgdTU+5rQWbKS684hPrfJFrTh8QuBUnRvBJCzngmT7MPywsfhgStxRvdr8M94YqHxnAZjpO4YoumdaGKg+tvzrFBYXI9E6o8lm8q2lcg9ZYATmPTZg9GUhZL2BnqPKvI8WwBSfrFB8MVX6/J6v5WINdRaZWNVenuBLz8WivY32d4m/d1V2nGOoUd9QpLmlzeFfczDrFs3WKT0/YuhvfG5p36VCZfGhe68dDlQ9cBMio5vU4gNcn7P1mhndwD9a0/A/d8Us0ze5zPNI9MwyagjZpLscubGsxBd2CjZpR4EVsHjoF9Qnepun9xzQNbBy/lC3Xaua5RZhXZN8Qqvxlv/MGAnUB3oSHNZPqzZhWXH/gKxxGClU+Neyc/wF0QB/qGtoTJAAAAABJRU5ErkJggg==",
      accumlates: [
        {
          day: 7,
          img: require("@/assets/task/sign_get_1.png"),
          jb: 10,
        },
        {
          day: 14,
          jb: 30,
          img: require("@/assets/task/sigin_get_2.png"),
        },
        {
          jb: 66,
          day: 28,
          img: require("@/assets/task/sigin_get_3.png"),
        },
      ],
      dayNum: 0,
      money: null,
      status: false,
    };
  },
  created() {
    this.userSign();
  },
  methods: {
    async userSign() {
      const params = {
        player_id: localStorage.getItem("id"),
      };
      const {
        data: { data },
      } = await this.$axios.post("/index/User/sign", this.$qs.stringify(params));
      this.$axios.post("/index/User/sign", this.$qs.stringify(params)).then((e) => {
        if (e.data.data.sign == 1) {
          this.status = true;
        }
      });
      this.dayNum = data.list[0].sign;
    },
    async startSign() {
      const params = {
        player_id: localStorage.getItem("id"),
      };
      const { data } = await this.$axios.post(
        "/index/User/sign_in",
        this.$qs.stringify(params)
      );
      if (data.status) {
        this.status = true;
        this.$message.success("签到成功");
        this.dayNum += 1;
      } else {
        this.$message.error(data.msg);
      }
    },
    async contniuSignRecharge(id) {
      const params = {
        player_id: localStorage.getItem("id"),
        sign__id: id,
      };
      const { data } = await this.$axios.post(
        "/index/User/sign_welfare",
        this.$qs.stringify(params)
      );
      if (data.status) {
        this.money = data.data;
        this.$message.success("领取成功");
      } else {
        this.$message.error(data.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 768px) {
  .sign_tips {
    width: 90% !important;
  }
  .total_sign-num {
    font-size: 28px !important;
  }
  .accumlate-item {
    span {
      font-size: 16px !important;
    }
  }
}

.sign-rule-box {
  transform: scale(1.4) !important;
  margin: 140px 0 !important;
}
.win {
  width: 450px;
  padding: 20px;
  background-color: #1c2230;
  position: relative;
  box-sizing: border-box;
  border-radius: 10px;

  .win-span2 {
    padding: 10px 0;
    height: 20px;
    background-color: #3088FF;
    background-size: 100% 100%;
    border: 1px solid #ffea95;
    color: #000000;
    display: flex;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-top: 15px;
  }

  .win-span2:hover {
    cursor: pointer;
  }
}

//中奖后盒子
.win-box {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
  display: grid;
  place-items: center;
}
.sign-day {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  margin-top: 40px;

  .sign_tips {
    width: 80%;
    max-width: 700px;
    border-radius: 30px;
    mix-blend-mode: lighten;
  }

  .sign_immediates {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sign_immediate {
    max-width: 500px;
    width: 60%;
    cursor: pointer !important;
  }

  .total_sign-num {
    font-size: 32px;
    color: #fff;
    margin: 30px 0;
  }

  .accumlate {
    display: flex;
    align-items: center;
    margin-top: 20px;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      cursor: pointer;
      max-width: 400px;
      margin-top: 20px;

      img {
        width: 70%;
        cursor: pointer;
      }

      span {
        color: #fff;
        font-size: 18px;
      }
    }
  }

  .sign-rule {
    width: 350px;
    height: calc((625 / 850) * 350px);
    margin-top: 50px;
    margin-bottom: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../../../assets/task/sign_rule.png");
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .content {
      padding-bottom: 50px;
      height: calc(100% - 150px);
      overflow: auto;

      &-item {
        display: flex;
        padding: 0 20px;
        font-size: 15px;
        text-align: justify;
        margin-bottom: 5px;

        img {
          width: 17px;
          height: 17px;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
